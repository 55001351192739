// external imports
import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql, Link } from 'gatsby'
// local imports
import { darkGrey, red, grey } from '~/colors'

export default ({ data }) => (
    <Container>
        <StaticQuery
            query={graphql`
                {
                    posts: allMarkdownRemark(sort: { order: ASC, fields: [fileAbsolutePath] }) {
                        edges {
                            node {
                                id
                                frontmatter {
                                    title
                                    path
                                }
                            }
                        }
                    }
                }
            `}
            render={data => {
                // the first thing we have to do is group the posts by category
                const categories = data.posts.edges
                    .map(({ node }) => node)
                    .reduce((acc, node) => {
                        // the category for the node
                        let category
                        if (node.frontmatter.path.startsWith('/advanced')) {
                            category = 'advanced'
                        } else if (node.frontmatter.path.startsWith('/patterns')) {
                            category = 'patterns'
                        } else {
                            category = 'root'
                        }

                        return {
                            ...acc,
                            [category]: [...(acc[category] || []), node],
                        }
                    }, {})

                return (
                    <>
                        {categories.root.map(node => (
                            <PostLink key={node.id} post={node} />
                        ))}
                        <a
                            style={{ color: darkGrey }}
                            href="https://medium.com/@aaivazis/a-guide-to-graphql-schema-federation-part-1-995b639ac035"
                            _target="blank"
                        >
                            <Entry>Getting Started</Entry>
                        </a>
                        <CategoryHeader>Advanced Topics</CategoryHeader>
                        {categories.advanced.map(node => (
                            <PostLink key={node.id} post={node} />
                        ))}
                        <ComingSoon>Caching Query Plans</ComingSoon>
                        <CategoryHeader>Patterns</CategoryHeader>
                        {categories.patterns.map(node => (
                            <PostLink key={node.id} post={node} />
                        ))}
                        <ComingSoon>Schema Changes</ComingSoon>
                    </>
                )
            }}
        />
    </Container>
)

const PostLink = ({ post }) => (
    <Link
        style={{
            color: darkGrey,
        }}
        activeStyle={{
            color: red,
        }}
        to={post.frontmatter.path}
    >
        <Entry>{post.frontmatter.title}</Entry>
    </Link>
)

const ComingSoon = ({ children }) => (
    <ComingSoonContainer>
        <Entry>{children} (coming soon)</Entry>
    </ComingSoonContainer>
)

const ComingSoonContainer = styled.div`
    color: ${grey};
`

const Container = styled.div`
    flex-direction: column;
`

const Entry = styled.div`
    font-size: 16px;
    margin-bottom: 9px;
`

const CategoryHeader = styled.div`
    margin-top: 32px;
    margin-bottom: 9px;
    font-family: 'Mark Medium';
    font-size: 19px;
`
