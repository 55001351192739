import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
// local imports
import { red, darkGrey } from '~/colors'
import LogoImage from '~/images/logo.svg'
import Posts from './Posts'

import './layout.css'
import './fonts.css'

export default ({ children }) => (
    <Container>
        <Sidebar>
            <Logo />

            <Posts />
        </Sidebar>
        <Content>{children}</Content>
    </Container>
)

const Logo = () => (
    <Header>
        <Link to="/" style={{ color: darkGrey }}>
            <LogoImage style={{ width: 27, height: 36, marginBottom: -8, marginRight: 12 }} />
            <span style={{ color: red }}>/</span> Gateway
        </Link>
    </Header>
)

const Container = styled.div`
    width: '100%';
    height: 100vh;
    display: flex;
    flex-direction: row;
`

// the sidebar
const Sidebar = styled.aside`
    width: 350px;
    height: 100%;
    padding: 35px 42px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`

const Content = styled.main`
    display: flex;
    flex-grow: 1;
    width: 0px;
`

const Header = styled.span`
    font-family: 'Mark Regular';
    font-size: 29px;
    margin-bottom: 32px;
`
