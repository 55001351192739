// external imports
import React from 'react'
import styled from 'styled-components'
// local imports
import { Layout, SEO } from '~/components'
import { darkGrey } from '~/colors'
import Logo from '~/images/logo.svg'

const Splash = styled.div`
    position: relative;
    background: ${darkGrey};
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const SplashText = styled.div`
    font-size: 69px;
    font-family: 'Mark Light';
    color: white;
    margin-bottom: 9px;
`

const SplashSubtext = styled.div`
    font-size: 29px;
    font-family: 'Mark Light';
    color: white;
`

const IndexPage = () => (
    <Layout>
        <SEO title="Home" keywords={['nautilus', 'graphql', 'schema', 'federation']} />
        <Splash>
            <div
                style={{
                    marginLeft: 42,
                    marginTop: 24,
                    width: '75%',
                    zIndex: 5,
                    position: 'relative',
                }}
            >
                <SplashText>Gateway</SplashText>
                <SplashSubtext>
                    Effortlessly consolidate your GraphQL services into a single endpoint.
                </SplashSubtext>
            </div>

            <Logo
                style={{
                    width: '40%',
                    height: '90%',
                    color: '#404040',
                    position: 'absolute',
                    bottom: '-20%',
                    right: '-3%',
                    zIndex: 1,
                }}
            />
        </Splash>
    </Layout>
)

export default IndexPage
